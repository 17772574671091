import React, { Component } from "react";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Arrow_Small_Left from "../../Assets/Icons/Arrow_Small_Left.svg";
import Arrow_Small_Right from "../../Assets/Icons/Arrow_Small_Right.svg";

export default class Notice_Carosel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    const Notice_data = [
      {
        tittle: "Notice Board",
        description: "Admission starts from October 2024.",
        action_button: "Apply",
      },
    ];

    return (
      <div>
        <Slider className="mx-5" ref={(c) => (this.slider = c)} {...settings}>
          {Notice_data &&
            Notice_data.map((item, index) => {
              return (
                <Card key={index} className="background-light">
                  <CardActionArea>
                    <CardContent className="CardDiscerption">
                      <Typography
                        className="m-0"
                        style={{ color: "#000" }}
                        gutterBottom
                        variant="h6"
                        component="p"
                      >
                        {item.tittle}
                      </Typography>
                      <Typography variant="span" style={{ fontSize: "14px" }}>
                        {item.description}
                      </Typography>
                    </CardContent>
                    <CardActions className="d-flex align-items-center justify-content-end">
                      <Button
                        disabled
                        style={{
                          background: this.props.disabled
                            ? "rgba(241, 82, 21, 1)"
                            : "rgba(128, 128, 128, 0.5)",
                          padding: "10px 36px",
                          borderRadius: "6px",
                          color: "rgba(255, 255, 255, 1)",
                        }}
                      >
                        {item.action_button}
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              );
            })}
        </Slider>
        {/* <div className="mt-4 d-flex justify-content-center" style={{ textAlign: "center" }}>
                    <p className="mx-3" onClick={this.previous}>
                        <span><img src={Arrow_Small_Left} /></span> Previous Brand orange color
                    </p>
                    <p className="mx-3" onClick={this.next}>
                        Next <span><img src={Arrow_Small_Right} /></span>
                    </p>
                </div> */}
      </div>
    );
  }
}
