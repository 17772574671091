import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CardActionArea,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,Grid,Typography,CardContent,Card
} from "@mui/material";
import ExpandableText from "../../Pages/HS/ExpandableText";
import Black_Arrow from "../../Assets/Icons/Black_Arrow.svg";
import ImageCarosel from "../../Component/Carosel/Image_Carosel";

const CardEvent = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const openDialog = (index) => {
    setSelectedEventIndex(index);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedEventIndex(null);
    setDialogOpen(false);
    setShowFullDescription(false);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const eventData = props.getdata[0];
  const eventgetdata = props.getdata;

  const navigateToActivitiesPage = () => {
    const { pathname } = location;
    const isNotHome = pathname === "/home";
    const path = isNotHome ? "/activity" : `${pathname}/subject-activities`;
    navigate(path);
  }
  return (
    <section>
      <div className="my-5">
      <div className="text-end">
          <Button
            size="large"
            sx={{ color: "rgb(223,82,38)" }}
            onClick={() => navigateToActivitiesPage(eventData.department)}
          >
            See All
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="px-2">
            <img
              height={340}
              className="w-100 cursor-pointer card_image_A"
              src={
                props.getdata[0].image[0] && props.getdata[0].image.length === 0
                  ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                  : props.getdata[0].image[0]
              }
              onClick={() => openDialog(0)}
              alt={`card_image_0`}
            />
          </Grid>
          <Grid item xs={12} md={6} className="pl-3 d-flex align-items-center">
            <div className="d-block">
              <Typography component="div">
                <Typography variant="h6">{props.getdata[0].tittle}</Typography>
                <ExpandableText text={props.getdata[0].description} initialChars={500} showFullText={false} />
                </Typography>
              <div className="d-flex align-items-center justify-content-between">
                <Button size="small">{props.getdata[0].event_date}</Button>
                <img src={Black_Arrow} alt="BlackArrow" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        {props.getdata &&
          props.getdata.slice(1, 4).map((items, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card className="ECard mx-2">
                <CardActionArea>
                  <img
                    height="354"
                    src={
                      items.image && items.image[0] && items.image.length > 0
                        ? items.image[0]
                        : "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                    }
                    alt={`card_image_${index + 1}`}
                    className="card_image_A"
                    onClick={() => openDialog(index + 1)}
                  />
                  <CardContent>
                    <Typography
                      className="m-0 text-truncate"
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {items.tittle}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="multine-ellipsis hide_description"
                    >
                      {items.description}
                    </Typography>
                  </CardContent>
                  <Dialog
                    open={dialogOpen && selectedEventIndex === index + 1}
                    onClose={closeDialog}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle>{items.tittle}</DialogTitle>
                    <DialogContent>
                      {dialogOpen && selectedEventIndex === index + 1 && (
                        <>
                          <Typography component="div">
                            <ExpandableText text={items.description} initialChars={100} showFullText={false}/>
                          </Typography>
                          <ImageCarosel image_data={items.image} />
                        </>
                      )}
                      {/* <img
                        src={
                          items.image && items.image[0] && items.image.length > 0
                            ? items.image[0]
                            : "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                        }
                        alt={`card_image_${index + 1}`}
                        className="mx-auto d-block cursor-pointer w-100 my-1"
                  
                      /> */}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeDialog} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <CardActions className="d-flex align-items-center justify-content-between">
                    <Button size="small">{items.event_date}</Button>
                    <img src={Black_Arrow} alt="BlackArrow" />
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Dialog
        open={dialogOpen && selectedEventIndex === 0}
        onClose={closeDialog}
        maxWidth="lg"
        fullWidth
      >
         <DialogTitle>
          <div>
            <Typography variant="h5">{props.getdata[0].tittle}</Typography>
            <ExpandableText text={props.getdata[0].description} initialChars={100} showFullText={false} />
          </div>
        </DialogTitle>
        <DialogContent>
          <img
            src={
              props.getdata[0].image[0] && props.getdata[0].image.length === 0
                ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                : props.getdata[0].image[0]
            }
            style={{ height: "300px" }}
            alt="Events Popup"
            className="mx-auto d-block cursor-pointer my-1"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default CardEvent;