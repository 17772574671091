import React from "react";
import "../../Styles/Study_with_us.css";
import { Grid, Typography} from "@mui/material";
import ExpandableText from "../../Pages/HS/ExpandableText";
import NoticeCarosel from "../../Component/Carosel/Notice_Carosel";

const Study_with_us = () => {
  const Study_with = {
    SU: {
      title: "Study With Us",
      description:
        "Dr. B. R. Ambedkar Institute of Management and Technology, an integral part of Dr.B.R. Ambedkar Educational Institutions was established in the year 2008, by our founder chairman late G. Venkataswamy Garu, the then Member of Parliament and Deputy Floor leader during 2000-2001. He was also a visionary for a progressive society. Our college is a distinguished institution located in the heart of Hyderabad City, set across a serene 4-acre landscape with splendid architecture and lush gardens, providing an ideal environment for management studies. Affiliated with Osmania University, Hyderabad, and established in 2008, the institute has been consistently upgrading its infrastructure and facilities to facilitate excellence in teaching, research, consultation, and professional activities. At the core of its academic offerings is the MBA program, approved by AICTE, which serves as a testament to the institute's commitment to quality education. The faculty members, comprising dedicated professionals with strong academic backgrounds and extensive industrial experience, continually refine their course contents through a rigorous process of appraisal and feedback from students. This ensures that the curriculum remains relevant and effective in meeting the demands of the ever-evolving business landscape. It is a moment of pride for our college for having completed glorious 16 years of establishment and has been successful in producing management professionals, business professionals, and entrepreneurs.",
    },
  };

  return (
    <div className="studyWithUsStyles">
      <section className="container my-3">
        <Typography variant="h4" className="mb-4" style={{ color: "white" }}>{Study_with.SU.title}</Typography>
        <Grid container className="description" style={{ display: "flex" }}>
          <Grid item xs={12} md={6} className="pl-3 d-flex align-items-top item" style={{ paddingLeft: "10px", flex: "0% 1 1%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <ExpandableText text={Study_with.SU.description} style={{ color: "white" }} buttonStyle={{ color: "white" }} initialChars={200} showFullText={false} /></div>
          </Grid>
          <Grid item xs={12} md={6} className="notice-container" style={{ margin: "0px", width: "100%", height: "auto", display: "flex", flexDirection: "column" }}>
            <NoticeCarosel />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default Study_with_us;
