import React from "react";
import "../Styles/fstyle.css";
import logo from "../Assets/Logo/logo.png";
import logo_name1 from "../Assets/Logo/college_name_1.png";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import JSONDATA from "../Assets/JSON/MV.json";
const Footer = () => {
  return (
    <section className="">
      <div className="container py-3">
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
              className="py-2 align-items-center"
            >
              <img src={logo} width={60} alt="C_Logo" />
              <img
                src={logo_name1}
                width={200}
                alt="C_Name"
                height={60}
                className="mx-2"
              />
            </Typography>
            <Typography variant="p" className="fs-15 heading_bold" component="div" sx={{ my: 2 }}>
            ICET:BRAB
            </Typography>
            <Typography
              variant="p"
              component="div"
              sx={{ my: 2 }}
              className="fs-14"
            >
              Dr. BR Ambedkar Institute of Management and Technology
              <br />
              AP Housing Board, Bagh Lingampally,
              <br />
              New Nallakunta, Hyderabad,
              <br />
              Telangana-500044.
              <br />
              Email:<a href="mailto:principal.mba@dbraei.edu.in">principal.mba@dbraei.edu.in</a>
              <br />
              Contact: <a href="tel:040-27670675">040 – 2767 06 75</a>/<a href="tel:+919100221282">9100221282</a>/<a href="tel:+918331041175">8331041174</a>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className="d-flex justify-content-center">
            <Typography variant="div" sx={{ my: 4 }}>
              <Typography
                variant="h5"
                display={{ fontWeight: "700" }}
                textAlign="center"
              >
                Our Courses
              </Typography>
              <Grid container spacing={2}>
                    
                    <Grid className="pt-4">
                      <Typography variant="h6" textAlign="center">
                    MBA- 2Years
                    </Typography>
                    <Typography variant="ul" sx={{ my: 2 }} display={{ listStyle: "none" }} className="fs-14 text-center">
                        {JSONDATA?.MBA_Courses.map((items, index) => (
                            <li className="my-1" key={index}>{items.title}</li>
                        ))}
                    </Typography>
                </Grid>
            </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className="d-flex justify-content-center">
            <Typography variant="div" sx={{ my: 4 }}>
              <Typography
                variant="h5"
                sx={{ my: 2 }}
                display={{ fontWeight: "700" }}
              >
                Know about us
              </Typography>
              <Typography
                variant="ul"
                sx={{ my: 2 }}
                display={{ listStyle: "none" }}
                className="fs-14"
              >
                 <Link to="/home"className="rotue_link text-dark">
                  <li className="my-1">About Us</li>
                </Link>
                <Link to="/contactus" className="rotue_link text-dark">
                  <li className="my-1">Contact</li>
                </Link>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Footer;
