import React from "react";
import "./Carosel.css";
import header_image from "../../Assets/Images/Rectangle 27.png"
import Header_Carosel from "./Header_Carosel";

const HCarosel = () => {
    return (
      <Header_Carosel />
    );
};
export default HCarosel
