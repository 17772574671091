import React from "react";
import "../../Styles/Socialbar.css";
import Instagram_icon from '../../Assets/Icons/instagram-fill.svg';
import  youtube_icon from '../../Assets/Icons/youtube-fill.svg';


const Brand_Twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0,0,256,256"
  >
    <g
      fill="#ffffff"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
    >
      <g transform="scale(10.66667,10.66667)">
        <path d="M22,3.999c-0.78,0.463 -2.345,1.094 -3.265,1.276c-0.027,0.007 -0.049,0.016 -0.075,0.023c-0.813,-0.802 -1.927,-1.299 -3.16,-1.299c-2.485,0 -4.5,2.015 -4.5,4.5c0,0.131 -0.011,0.372 0,0.5c-3.353,0 -5.905,-1.756 -7.735,-4c-0.199,0.5 -0.286,1.29 -0.286,2.032c0,1.401 1.095,2.777 2.8,3.63c-0.314,0.081 -0.66,0.139 -1.02,0.139c-0.581,0 -1.196,-0.153 -1.759,-0.617c0,0.017 0,0.033 0,0.051c0,1.958 2.078,3.291 3.926,3.662c-0.375,0.221 -1.131,0.243 -1.5,0.243c-0.26,0 -1.18,-0.119 -1.426,-0.165c0.514,1.605 2.368,2.507 4.135,2.539c-1.382,1.084 -2.341,1.486 -5.171,1.486h-0.964c1.788,1.146 4.065,2.001 6.347,2.001c7.43,0 11.653,-5.663 11.653,-11.001c0,-0.086 -0.002,-0.266 -0.005,-0.447c0,-0.018 0.005,-0.035 0.005,-0.053c0,-0.027 -0.008,-0.053 -0.008,-0.08c-0.003,-0.136 -0.006,-0.263 -0.009,-0.329c0.79,-0.57 1.475,-1.281 2.017,-2.091c-0.725,0.322 -1.503,0.538 -2.32,0.636c0.834,-0.5 2.019,-1.692 2.32,-2.636z"></path>
      </g>
    </g>
  </svg>
);

const SocialBar = () => {
  return (
    <div className="social-bar">
      <a
        className="twitter"
        href="https://twitter.com/dbraei.edu.in"
        target="_blank"
        rel="noopener noreferrer"
      >
        {Brand_Twitter}
      </a>
      <a
        className="instagram"
        href="https://www.instagram.com/alc_sba?utm_source=qr&igsh=MXVxOWpyNDloajJyNw=="
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Instagram_icon} alt="Instagram Icon"/>
      </a>
      <a
        className="youtube"
        href="https://www.youtube.com/@dr.b.r.ambedkarcollege7526"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={youtube_icon} alt="Youtube Icon"/>
      </a>
    </div>
  );
};

export default SocialBar;
