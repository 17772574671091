import React, { useState } from "react";
// import Button from "@mui/material/Button";
import { Typography, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ExpandableText = ({ text, initialChars, showFullText, style, buttonStyle}) => {
  const [showAll, setShowAll] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleShowAllClick = () => {
    setShowAll(!showAll);
  };
  const truncatedText = showAll? text : text.slice(0, initialChars) + (text.length > initialChars? "..." : "");
  return (
    <div>
        <Typography variant="body1" className="studyDescription" style={{ ...style, margin: "auto" }}>
      {truncatedText}
      {!showAll && text.length > initialChars && (
        <Button variant="text" style={buttonStyle} onClick={handleShowAllClick}>
          Read More
        </Button>
      )}
      {showAll && (
        <Button variant="text" style={buttonStyle} onClick={handleShowAllClick}>
          Read Less
        </Button>
      )}
    </Typography>
   
    </div>
  );
};
export default ExpandableText;
