import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import _set from "lodash/set";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _keys from "lodash/keys";
// import _findIndex from 'lodash/findIndex';
import feedback_data from "../../Assets/JSON/Feedbackjson/Feedback.json";
import Feedback_DropDown_Data from "../../Assets/JSON/Feedbackjson/Feedback_dropdown.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import SubmittedImage from "../../Assets/Images/FeedbackSubmitted.png";
import Axios from "axios";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";
import "../../Styles/Feedback.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Feedback = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [feedBackData, setFeedBackData] = useState({
    ...feedback_data.Feedback_data
  });
  const [activeButton, setActiveButton] = useState(0);
  const [Submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const activeFormName = _get(
    feedback_data,
    `Feedback_forms[${activeButton}].match_name`,
    ""
  );
  const activeForm = _get(feedback_data, `Feedback_data.${activeFormName}`, {});
  const formTabs = _keys(_get(activeForm, "tabs", {}));
  const activeTab = formTabs.indexOf(selectedTab);

  useEffect(() => {

    setActiveButton(0);
    setFeedBackData(formTabs);
}, []);

  const onChangeEvent = (path, event) => {
    _set(feedBackData, path, event.target.value);
    setFeedBackData({ ...feedBackData });
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setSelectedTab("");
  };

const onSubmit = async () => {
    try {
      const apiUrl = "https://devapi.dbraei.edu.in/api/v1/feedbacks";
  
      const updatedFeedBackData = {
        user_id: 1,
        student_id: 1,
        teacher_id: 0,
        feedback_forms_json: {...feedBackData[activeFormName]},
        department_name: "",
        department_code: "",
        college_id: 1,
        college_name: "abcd",
        created_by: 1,
        updated_by: 1,
      };
  
      const response = await Axios.post(apiUrl, updatedFeedBackData, {
        timeout: 5000,
        headers: {
          "Content-Type": "application/json",
        },
      });
      
            console.log("Submitted Data", updatedFeedBackData);
      console.log("API Response: ", response.data);

      // Reset the feedback data to its initial state
      setFeedBackData({ ...feedback_data.Feedback_data });
      setSelectedTab(""); // Reset the selected tab
      setSubmitted(true); // Indicate that submission was successful
  
    } catch (error) {
      console.error("Error submitting data:", error);
      // Handle errors as needed
      toast.error("Error: Something Went Wrong..!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  

  const onNext = () => {
    setSelectedTab(formTabs[activeTab + 1]);
  };

  const text_input = (text, path) => {
    return (
      <div>
        <TextField
          id="standard-basic"
          onChange={(e) => onChangeEvent(path, e)}
          value={_get(feedBackData, path, "")}
          label={text.Name}
          variant="standard"
          className="w-100"
          type="text"
        />
      </div>
    );
  };

  const textarea_input = (text, path) => {
    return (
      <div>
        <TextField
          multiline
          rows={4}
          id="standard-basic"
          onChange={(e) => onChangeEvent(path, e)}
          value={_get(feedBackData, path, "")}
          label={text.Name}
          variant="standard"
          className="w-100"
        />
      </div>
    );
  };

  const dropdown_input = (dropdown, path) => {
    return (
      <TextField
        select
        label={dropdown.Name}
        helperText="Please select any Option"
        variant="standard"
        onChange={(e) => onChangeEvent(path, e)}
        value={_get(feedBackData, path, "")}
        className="w-100"
      >
        {Feedback_DropDown_Data[dropdown.match_name]?.map((option) => (
          <MenuItem key={option.Name} value={option.Name}>
            {option.Name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const yesNoRadioButton = (e, key, path) => {
    const value = e.target.checked;
    const options = {
      Yes: false,
      No: false,
    };
    options[key] = value;
    _set(feedBackData, path, options);
    setFeedBackData({ ...feedBackData });
  };

  const ratingRadioButton = (e, key, path) => {
    const value = e.target.checked;
    const options = {
      rating5: false,
      rating4: false,
      rating3: false,
      rating2: false,
      rating1: false,
    };
    options[key] = value;
    _set(feedBackData, path, options);
    setFeedBackData({ ...feedBackData });
  };

  const YesNo_Question = (YesNo_Data, path) => {
    console.log("Data:", YesNo_Data.Yes_No_Question);
    return (
      <div>
        {YesNo_Data?.Yes_No_Question?.map((YesNoQuestion, index) => (
          <div key={index} className="mb-3 color-blue">
            <p>{YesNoQuestion.questions}</p>
            <div>
              {Object.keys(YesNoQuestion?.options)?.map((key) => (
                <FormControlLabel
                  key={`${index}_${key}`}
                  control={<Radio />}
                  label={key.toString()}
                  onChange={(e) =>
                    yesNoRadioButton(
                      e,
                      key,
                      `${path}.Yes_No_Question[${index}].options`
                    )
                  }
                  checked={_get(
                    feedBackData,
                    `${path}.Yes_No_Question[${index}].options.${key}`,
                    false
                  )}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const rating_data = (feedback_Data, path) => {
    console.log("Checking:", feedback_Data);
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="table_header">
              {feedback_Data?.header?.map((items, index) => (
                <TableCell className="vertical_text" key={index}>
                  {items.title} {items.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {feedback_Data?.Questions?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.question}
                </TableCell>
                {Object.keys(row?.options)?.map((key) => (
                  <TableCell align="center" key={index} className="radio-cell">
                    <Radio
                      checked={_get(
                        feedBackData,
                        `${path}.Questions[${index}].options.${key}`,
                        false
                      )}
                      onChange={(e) =>
                        ratingRadioButton(
                          e,
                          key,
                          `${path}.Questions[${index}.options]`
                        )
                      }
                      value={key.toString()}
                      name={`radio-buttons-${row.id}`}
                      inputProps={{ "aria-label": key.toString() }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const validateText = (text, path) => {
    if (!_isEmpty(text.trim())) {
      _set(feedBackData, path, text.trim());
      setFeedBackData({ ...feedBackData });
      return true;
    } else {
      console.error("Text is required.");
      toast.error("Text is required.", {
        position: "top-right",
        autoClose: 5000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
  };

  const validateDropdown = (value, path) => {
    if (!_isEmpty(value)) {
      _set(feedBackData, path, value);
      setFeedBackData({ ...feedBackData });
      return true;
    } else {
      toast.error("Please select an option.", {
        position: "top-right",
        autoClose: 5000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
  };

  const onSub = (dta) => {
    setSelectedTab(dta);
    console.log("Result: ", dta);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");

    const isTextValid = validateText(
      _get(feedBackData, `${activeFormName}.headers.exampleText.value`),
      `${activeFormName}.headers.exampleText.value`
    );
    const isDropdownValid = validateDropdown(
      _get(feedBackData, `${activeFormName}.headers.exampleDropdown.value`),
      `${activeFormName}.headers.exampleDropdown.value`
    );

    if (isTextValid && isDropdownValid) {
      console.log("Form submitted successfully!");
      // Continue with form submission logic
    } else {
      console.error("Form submission failed due to validation errors.");
      // Display error message to the user
      toast.error(errorMessage || "Form validation failed.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <section className="container py-5">
      <ToastContainer />
      <div className="feedBack_container">
        {Submitted !== true ? (
          <div className="col-sm-4 mx-3">
            <div className="background-blue">
              <h4 className="m-0 p-2 text-white">Feedback Forms</h4>
            </div>
            {feedback_data.Feedback_forms &&
              feedback_data.Feedback_forms.map((items, index) => {
                const isActive = activeButton === index;
                return (
                  <div className="border d-flex align-items-center" key={index}>
                    <Button
                      variant="text"
                      style={{
                        width: "100%",
                        backgroundColor: isActive ? "gray" : "white",
                        color: isActive ? "white" : "blue",
                        borderRadius: "0px",
                      }}
                    >
                      <a
                        style={{ width: "100%" }}
                        onClick={() => {
                          handleButtonClick(index);
                        }}
                      >
                        <p className="m-0 p-2 cursor-pointer">
                          <b>{items.title}</b>
                        </p>
                      </a>
                    </Button>
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
        {Submitted !== true ? (
          !_isEmpty(activeForm) && (
            <div className="col-sm-8">
              <h4>{activeForm.title}</h4>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {Object.keys(activeForm.headers).map((items, index) => {
                    return (
                      <div key={index} className="col-sm-6">
                        {activeForm.headers[items].type === "dropdown" && (
                          <div>
                            {dropdown_input(
                              activeForm.headers[items],
                              `${activeFormName}.headers.${items}.value`
                            )}
                          </div>
                        )}
                        {activeForm.headers[items].type === "text" && (
                          <div>
                            {text_input(
                              activeForm.headers[items],
                              `${activeFormName}.headers.${items}.value`
                            )}
                          </div>
                        )}
                        {activeForm.headers[items].type === "textarea" && (
                          <div>
                            {textarea_input(
                              activeForm.headers[items],
                              `${activeFormName}.headers.${items}.value`
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex my-4">
                  {Object.keys(activeForm.tabs).map((items, index) => {
                    return (
                      <div className="mx-2" key={index}>
                        <Button
                          variant="contained"
                          sx={{
                            background: items === selectedTab ? "gray" : "",
                            "&:hover": {
                              background: items === selectedTab ? "gray" : "",
                            },
                          }}
                          onClick={() => onSub(items)}
                        >
                          {items}
                        </Button>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <React.Fragment>
                    <div className="row">
                      {Array.isArray(activeForm.tabs[selectedTab]) ? (
                        activeForm.tabs[selectedTab]?.map((items, index) => {
                          return (
                            <div className="col-sm-6" key={index}>
                              {items.type === "dropdown" && (
                                <div>
                                  {dropdown_input(
                                    items,
                                    `${activeFormName}.tabs.${selectedTab}[${index}].value`
                                  )}
                                </div>
                              )}
                              {items.type === "text" && (
                                <div>
                                  {text_input(
                                    items,
                                    `${activeFormName}.tabs.${selectedTab}[${index}].value`
                                  )}
                                </div>
                              )}
                              {items.type === "textarea" && (
                                <div>
                                  {textarea_input(
                                    items,
                                    `${activeFormName}.tabs.${selectedTab}[${index}].value`
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-sm-12">
                          {activeForm.tabs[selectedTab]?.type === "rating" && (
                            <div>
                              {YesNo_Question(
                                activeForm.tabs[selectedTab],
                                `${activeFormName}.tabs.${selectedTab}`
                              )}
                              {rating_data(
                                activeForm?.tabs[selectedTab],
                                `${activeFormName}.tabs.${selectedTab}`
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                </div>

                <div className="mt-3 text-end">
                  <Button
                    className="px-5"
                    variant="contained"
                    onClick={() =>
                      formTabs.length - 1 === activeTab ? onSubmit() : onNext()
                    }
                  >
                    {formTabs.length - 1 === activeTab ? "SUBMIT" : "Next"}
                  </Button>
                </div>
              </form>
            </div>
          )
        ) : (
          <Typography component="div" className="submission-message">
            <Typography
              component="img"
              src={SubmittedImage}
              alt="Submitted Successfully...!"
            ></Typography>
            <Link to="/">
              <Button varient="contained">Back To home</Button>
            </Link>
          </Typography>
        )}
      </div>
    </section>
  );
};

export default Feedback;
