import React from "react";
import { Typography } from "@mui/material";

// import image from "../../Assets/Images/Rectangle 27.png";

// import ImageModal from "../../Component/ImageModal";

const MVComponent = ({ getdata }) => {
  const data = getdata;
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

  return (
    <section>
      <div className="text-center px-5">
      <Typography variant="h5" className="text-center mb-4">{data.tittle}</Typography>
        <Typography variant="body1" className="multine-ellipsis-4 body1_color">{data.description}</Typography>
        <div>
          <img
            src={
              data.image === ""
                ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                : data.image
            }
            height={330}
            className="w-100 card_image_A mt-3"
            alt="MV-Data"
            // onClick={openModal}
          />
          {/* {isModalOpen && (
        <ImageModal imageUrl={data.image} onClose={closeModal} />
      )} */}
        </div>
      </div>
     
    </section>
  );
};

export default MVComponent;
