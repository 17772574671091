import React from 'react';
import { Modal, Button, Box, Typography } from '@mui/material';
import WelcomeImage from '../Assets/Images/Welcome_Modal1.jpg';

const WelcomeModal = ({ open, onClose }) => {

  const WelcomeData = [
    {
      "image": WelcomeImage,
      "title" : "Welcome to Our Website!",
      "description" : "Explore our portal for insights into our prestigious institution. From our legacy to our programs, embark on a journey of legal enlightenment. Start discovering today."
    }
  ]

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="welcome-modal-title"
      aria-describedby="welcome-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: 'white',
          outline: 'none',
          borderRadius: 4,
          textAlign: 'center',
          maxWidth: '50vw',
          padding: 3
        }}
      >
        <Typography component="img"
          src={WelcomeData[0].image}
          alt="WelcomeModal"
          style={{
            width: '40%',
            height: 'auto',
            marginBottom: 2,
            paddingBottom: "40px"
          }}
        />
        <Typography variant='h5' id="welcome-modal-title">{WelcomeData[0].title}</Typography>
        <Typography component="p"  sx ={{fontSize:"95%",lineHeight:"140%",textAlign:"left",color:"#5e5e5e",padding:"10px 50px"}} id="welcome-modal-description">{WelcomeData[0].description}</Typography>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            marginTop: 2,
            width: "15%",
            borderRadius: 10,
            p: 1,
            backgroundColor:"rgb(20,24,75)"
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default WelcomeModal;
