import React from "react";
// import { Link } from 'react-router-dom';
// import Arrow_icon_right from '../../Assets/Icons/Arrow_Small_Right.svg';
// import { Breadcrumbs, Typography } from "@mui/material";
import NIRFextended from "../NACC/NIRFExtended";


const NIRFComponent = () =>{
    return (
        <section className="container py-5">
            <div className="mb-4 text-center">
                <h2>NIRF Details</h2>
            </div>
            <div>
                <NIRFextended />
            </div>
        </section>
    );
}

export default NIRFComponent