import React from 'react';
import ErrorImage from './Assets/Images/404-error1.png';
import { Typography } from "@mui/material";

const Error404 = () => {
  return (
    <div style={{ position:"relative", bottom: "100px" ,display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
        <Typography component="img" src={ErrorImage} alt="404 Error" sx={{ width: '100%', maxWidth: '450px' }} />
        <Typography component="h1" sx={{ fontSize:"80px",fontWeight: "600"}}> Oops! Something Went Wrong :) </Typography>
    </div>
  );
}
export default Error404;
