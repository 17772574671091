import React, { Component } from "react";
import Slider from "react-slick";
import { Typography } from '@mui/material';
import Banner from '../../Assets/JSON/MV.json';

export default class Header_Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      // fade: true,
      infinite: true,
      slidesToShow: 1,
      speed: 250,
      slidesToScroll: 1
    };

    return (
      <section>
        <Slider {...settings}>
          {Banner.HomePage_Details.Banners.map((banner, index) => (
            <div key={index}>
              <Typography component="img" src={banner} height={500} className="w-100 objectFit" alt={`banner-${index}`} />
            </div>
          ))}
        </Slider>
      </section>
    );
  }
}