import React, { useState } from "react";
import Black_Arrow from "../../Assets/Icons/Black_Arrow.svg";
import HCarosel from "../../Component/Carosel/Carosel";
import Json_Data from "../../Assets/JSON/MV.json";
import "../../Styles/Activity.css";
import ImageCarosel from "../../Component/Carosel/Image_Carosel";
import ExpandableText from "../../Pages/HS/ExpandableText";
import moment from "moment";
import {
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogTitle,
  CardActions,
  DialogContent,
  DialogActions,
} from "@mui/material";

const Activity = () => {
  const Highlights_data = Json_Data?.HomePage_Details?.Events_Activities;
  const [Filter_Data, setFilter_data] = useState(Highlights_data);
  const [filter_date, setfilter_date] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsDialogOpen(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setIsDialogOpen(false);
  };

  const filterdata = (filter_date) => {
    let filte_data = Highlights_data.filter(
      (item) => moment(item.event_date).year().toString() === filter_date.toString()
    );
    setFilter_data(filte_data);
  };

  const handleChange = (event) => {
    setfilter_date(event.target.value);
    filterdata(event.target.value);
  };

  return (
    <section>
      <div className="my-4">
        <HCarosel />
      </div>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="mt-4">Events And Activities</h4>
          </div>
          <div>
            <FormControl variant="standard" sx={{ p: 0, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Year
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filter_date}
                onChange={handleChange}
                label="Year"
              >
                <MenuItem value={2018}>2018</MenuItem>
                <MenuItem value={2019}>2019</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <Grid container className="py-4">
            {Filter_Data.length > 0 ? (
              <React.Fragment>
                {Filter_Data &&
                  Filter_Data?.slice(0, 2).map((item, index) => (
                    <Grid xs={12} md={6} className="my-2" key={index}>
                      <Card className="ECard mx-2">
                        <CardActionArea
                          onClick={() => openImageModal(item.image)}
                        >
                          <ImageCarosel
                            image_data={item.image}
                            onImageClick={(image) => openImageModal(image)}
                            sx={{objectFit :"cover"}}
                          />
                          <CardContent>
                            <Typography
                              className="m-0"
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {item.tittle}
                            </Typography>
                            <Typography
                              className="activity_description"
                              variant="body2"
                              color="text.secondary"
                            >
                              {item.description}
                            </Typography>
                          </CardContent>
                          <CardActions className="d-flex align-items-center justify-content-between">
                            <Button size="small">{item?.event_date}</Button>
                            <img src={Black_Arrow} alt="Arrow" />
                          </CardActions>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                {Filter_Data &&
                  Filter_Data?.slice(2).map((item, index) => (
                    <Grid xs={12} md={4} className="my-2" key={index}>
                      <Card className="ECard mx-2">
                        <CardActionArea
                          onClick={() => openImageModal(item.image)}
                        >
                          <ImageCarosel image_data={item.image}/>
                          <CardContent>
                            <Typography
                              className="m-0"
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {item.tittle}
                            </Typography>
                            <Typography
                              className="activity_description"
                              variant="body2"
                              color="text.secondary"
                            >
                              {item.description}
                            </Typography>
                          </CardContent>
                          <CardActions className="d-flex align-items-center justify-content-between">
                            <img src={Black_Arrow} alt="Arrow" />
                          </CardActions>

                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>
                  <h6>No Data Found</h6>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </div>
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={closeImageModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedImage &&
            Filter_Data.map((item, i) => {
              if (item.image === selectedImage) {
                return (
                  <div key={i}>
                    <h4>{item.tittle}</h4>
                    <ExpandableText text={item.description} initialChars={100} showFullText={false} />
                  </div>
                );
              }
              return null;
            })}
        </DialogTitle>
        <DialogContent>
          {selectedImage && <ImageCarosel isDialogOpen={openImageModal} image_data={selectedImage}/>}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImageModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Activity;
