import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Slide,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../Assets/Logo/logo.png";
import phone_black_icon from "../Assets/Icons/phone_black.svg";
import JSONDATA from "../Assets/JSON/MV.json";
import "../Styles/Style.css";
import FounderImage from "../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";
import logo_name1 from "../Assets/Logo/college_name_1.png";

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

const drawerWidth = 200;
const navItems = [
  {
    id: 1,
    title: "About",
    pathname: "/about",
    subnav_flag: true,
    subnav: [
      {
        title: "About Us",
        pathname: "/aboutus",
      },
      {
        title: "InfraStructure",
        pathname: "/infrastructure",
      },
    ],
  },
  {
    id: 2,
    title: "Home",
    pathname: "/home",
  },
  {
    id: 3,
    title: "Courses",
    pathname: "/courseoffering",
    inactive: false,
    
  },
  {
    id: 4,
    title: "Faculty",
    pathname: "/faculty",
  },
  {
    id: 5,
    title: "IQAC",
    pathname: "/iqac",
    subnav_flag: true,
    inactive: true,
    subnav: [
      {
        title: "About IQAC",
        pathname: "/aboutIQAC",
      },
      {
        title: "Quality Policy",
        pathname: "/Qualitypolicy",
      },
      {
        title: "IQAC Objectives",
        pathname: "/IQACObjective",
      },
      {
        title: "Parameters of IQAC",
        pathname: "/IQACParameter",
      },
      {
        title: "Working Committee",
        pathname: "/workingcommitee",
        disabled: true,
      },
      {
        title: "Mandatory Document",
        pathname: "/mandatorydoc",
        
      },
    ],
  },
  {
    id: 6,
    title: "NIRF",
    pathname: "/nirf",
    inactive: true,
  },
  {
    id: 7,
    title: "Activities",
    pathname: "/activity",
    inactive: true,
  },
  {
    id: 8,
    title: "Contact us",
    pathname: "/contactus",
  },
  // {
  //   id: 8,
  //   title: "Students Corner",
  //   pathname: "/StudentCorner",
  //   subnav_flag: true,
  //   subnav: [
  //     {
  //       title: "NCC",
  //       pathname: "/NCC",
  //     },
  //     {
  //       title: "NSS",
  //       pathname: "/NSS",
  //     },
  //     {
  //       title: "Sports",
  //       pathname: "/sports",
  //     },
  //   ],
  // },
];

const subnavItems = [
  {
    id: 1,
    title: "Placements",
    pathname: "/placements",
    inactive: true,
    subnav: [
      {
        title: "overview",
        pathname: "/placements",
        inactive: false,
      },
      {
        title: "Training",
        pathname: "/feedback",
        inactive: false,
      },
    ],
  },
  {
    id: 2,
    title: "Feedback",
    pathname: "/feedback",
    inactive: true,
  },
  {
    id: 3,
    title: "Student",
    pathname: "/home",
    inactive: true,
  },

  {
    id: 4,
    title: "Staff",
    pathname: "/home",
    inactive: true,
  },
  {
    id: 5,
    title: "Alumni",
    pathname: "/home",
    inactive: true,
  },
  {
    id: 6,
    title: "Careers",
    pathname: "/home",
    inactive: true,
  },
];

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navigateToRoute = (data) => {
    if (data.title === "Courses") {
      handleClickOpen();
    } else if (data.subnav_flag) {
      setSelectedMenuItem(data.id);
    } else {
      navigate(data.pathname);
      setSelectedMenuItem(null);
    }
  };

  const isMenuItemSelected = (item) => {
    if (location.pathname === item.pathname) {
      return true;
    }
    if (item.subnav) {
      return item.subnav.some((subItem) =>
        location.pathname.includes(subItem.pathname)
      );
    }
    return false;
  };

  const Navigation_Sub_route = (data, dep) => {
    handleDialogClose();
    const selected_data = { College: data, CourseOffered: dep };
    navigate(
      "/courseoffering/" +
        data.title.toLowerCase().replace(/ /g, "-") +
        "/" +
        dep.title.toLowerCase().replace(/ /g, "-"),
      { state: { College_details: selected_data } }
    );
  };

  return (
    <section className="Hbox">
    <React.Fragment>
    
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <div className="text-start px-3 py-1">
          <Typography variant="body2" onClick={handleDrawerToggle} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </Typography>
        </div>
        <div style={{ width: drawerWidth }}>
          <List>
            {navItems.map((item) => (
              <ListItem button key={item.id}  selected={isMenuItemSelected(item)} onClick={() => navigateToRoute(item)}>
                <ListItemText className="pl-2 heading_bold" primary={item.title} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {subnavItems.map((item) => (
              <ListItem button key={item.id} selected={isMenuItemSelected(item)} onClick={() => navigateToRoute(item)} disabled={item.inactive}>
                <ListItemText primary={item.title} className="pl-2 heading_bold" />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      <Dialog
          fullScreen
          open={open}
          onClose={handleDialogClose}
          TransitionComponent={Slide}
          TransitionProps={{
            direction: "up",
          }}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="Header_Dialog"
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              top: "20%",
            },
          }}
        >
          <div className="text-end py-3 px-5">
            <Typography
              variant="body1"
              onClick={handleDialogClose}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon />
            </Typography>
          </div>
          <Grid
            container
            spacing={2}
            className="px-5 py-5"
            sx={{ display: "relative", width: "100%" }}
          >
            {JSONDATA.sub_heading &&
              JSONDATA.sub_heading?.map((items, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    className="my-1 cart_nav text-center justify-content-center"
                  >
                    <div style={{ textAlign: "center" }}>
                      <Typography variant="h4">{items.title}</Typography>
                      {items.subdeparment?.map((sub_data, i) => {
                        return (
                          <div style={{ textAlign: "center" }} key={i}>
                            <Typography
                              variant="body1"
                              className="m-0 heading_bold cursor-pointer"
                              onClick={() => {
                                Navigation_Sub_route(items, sub_data);
                              }}
                            >
                              {sub_data.title}
                            </Typography>
                          </div>
                        );
                      })}
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </Dialog>
    </React.Fragment>

    <CssBaseline />
    <AppBar component="nav" color="inherit" className="box-shadow_soft" id="nav">
      <div className="container  pt-2">
        <div className="row d-flex justify-content-between">
          <div className="col-sm-5" id="Header">
            <div className="col-sm-2">
              <img className="col-m-7" style={{ padding: "5px", objectFit: "cover", maxWidth: "100px" }} src={FounderImage} alt="Founder" />
              <div>
              <Typography
                    variant="body1"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
                    className="pt-2 justify-content-between"
                  >
                    <div style={{ width: "30vw" }}>
                      <img src={phone_black_icon} alt="Phone Icon" />
                      <small className="mx-1">
                        <a href="tel:040-27670675">040 – 2767 06 75</a>/
                        <a href="tel:+919100221282">+91 9100221282</a>/
                        <a href="tel:+918331041174">+91 8331041174</a>
                      </small>
                    </div>
                    <div>
                      <small className="fs-15 heading_bold">ICET:BRAB</small>
                    </div>
                  </Typography>
                <Typography variant="h6" component="div" sx={{ display: { xs: "none", sm: "flex" } }} className="my-3 align-items-center">
                  <img src={logo} width={60} alt="Logo" />
                  <img src={logo_name1} className="mx-1 image-size" alt="Brand Name" />
                </Typography>
              </div>
            </div>
          </div>
          <div className="col-sm-7 text-end pt-3">
            {mobileOpen ? (
              <IconButton color="inherit" aria-label="open drawer" sx={{ display: { lg: "none" }, position: "absolute" }} edge="end" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: { xs: "none", lg: "block" }}}>
                {subnavItems.map((item) => (
                  <Button
                    key={item.id}
                    disabled={item.inactive}
                    onClick={() => navigateToRoute(item)}
                    sx={{
                      textTransform: "none",
                      color: "#333333",
                      fontSize: "14px",
                      padding: "0px 10px 20px 10px",
                      marginBottom: "10px"
                    }}
                    className="selected-item"
                  >
                    <Typography variant="body2">{item.title}</Typography>
                  </Button>
                ))}
              </Box>
            )}
            {!mobileOpen && (
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {navItems.map((item) => (
                  <React.Fragment key={item.id}>
                    <Button
                      id={`menu-anchor-${item.id}`}
                      disabled={item.inactive}
                      onClick={() => navigateToRoute(item)}
                      sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: isMenuItemSelected(item) ? "rgb(223,82,38)" : "#333333",
                        padding: "0px 5px 0px 10px",
                        // marginTop: "30px",
                        paddingBottom: "31px",
                        borderRadius: "0px",
                      }}
                      className="selected-item"
                    >
                      {item.title}
                    </Button>
                    {item.subnav_flag && (
                      <Menu
                        anchorEl={document.getElementById(`menu-anchor-${item.id}`)}
                        open={selectedMenuItem === item.id}
                        onClose={() => setSelectedMenuItem(null)}
                        anchorReference="anchorEl"
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      >
                        {item.subnav.map((subItem, subIndex) => (
                          <MenuItem
                            key={subIndex}
                            onClick={() => navigateToRoute(subItem)}
                            sx={{
                              backgroundColor: location.pathname.includes(subItem.pathname) ? "#3E9CE4" : "transparent",
                              color: location.pathname.includes(subItem.pathname) ? "white" : "inherit",
                            }}
                          >
                            <Typography variant="body2">{subItem.title}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            )}
          </div>
        </div>
      </div>
    </AppBar>
  </section>
  );
};

export default Header;