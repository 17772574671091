import { Grid, Typography } from '@mui/material';
import React from 'react';
import location from '../../Assets/Icons/location.svg';
import message from '../../Assets/Icons/message.svg';
import phoneIcon from '../../Assets/Icons/Phone_orage_icon.svg';

const ContactUs = () => {
  return (
    <section className='container my-3'>
      <Grid container spacing={2} className='p-3'>
        <Grid item xs={12} md={6} className="d-grid">
          <div>
            <Typography variant="h4">Contact Us</Typography>
            <Typography variant="body1">
              Got something that you would like posted to us? We’re all ears! Make sure you write in your contact information so we can get back to you at the earliest.
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className='d-flex align-items-center pb-2'>
                <Typography component="img" src={location} />
              </div>
              <Typography variant="body1">AP Housing Board, Bagh Lingampally, New Nallakunta, Hyderabad, Telangana 500044</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className='d-flex align-items-center pb-2'>
                <Typography component="img" src={message} />
              </div>
              <Typography variant="body1"><a href="mailto:principal.mba@dbraei.edu.in">principal.mba@dbraei.edu.in</a></Typography>
              <div className='d-flex align-items-center pb-2'>
                <Typography component="img" src={phoneIcon} />
              </div>
              <Typography variant="body1"><a href="tel:040-27670675">040 – 2767 06 75</a>/<a href="tel:+919100221282">9100221282</a>/<a href="tel:+918331041175">8331041174</a></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
        <iframe
            title="Google Maps Embed"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7614.449523899285!2d78.499843!3d17.400998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99bf3d11314b%3A0xc93bceef6f9b1a70!2sDr.%20BR%20Ambedkar%20college%2C%20AP%20Housing%20Board%2C%20Bagh%20Lingampally%2C%20New%20Nallakunta%2C%20Hyderabad%2C%20Telangana%20500044!5e0!3m2!1sen!2sin!4v1701691900210!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>
    </section>
  );
};

export default ContactUs;
