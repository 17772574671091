import React from "react";
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  BrowserRouter as Router
} from "react-router-dom";
import AppRoutes from './Routes.js';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    h4: {
      fontWeight: '500',
    },
    h5: {
      fontWeight: '600',
    }
  },
});

function App() {
  return (
    <Router>
       <ThemeProvider theme={theme}>
        <AppRoutes />
        </ThemeProvider> 
    </Router>
  );
}

export default App;
